<template>

    <div id="cesiumContainer">
        
    </div>

</template>


<script>
import { MapInit, MapViewInit, MapAddDom_Tianditu, MapViewerRemoveAll, MapAddDem, MapDebugCamera, MapFlyToDuration, MapAdd3DTiles, MapAddTiltPhoto } from './mapbase';
import { getCxfl, getCxxm } from '../../network/kjcx';
import { getMedia, getCode } from '../../network/media';

import { bd09togcj02, gcj02towgs84 } from '../../utils/coordinatesystem';


export default {
    data () {
        return {
            viewer :'',
            tileset: '',

			cxfl: {},
            cxflid: 0,
			mapCxxm: new Map(),		// cxflid, arrCxxm



            // 配套数据
            ptgcData: [],

            currentid: 0,  // 当前id（0=未选中）
            
			gisDemSetting: {},

            // 图标大小
            big_width: 54,
            big_height: 59,
            little_width: 36,
            little_height: 39,           

        }
    },
    
    mounted (){
        
        XE.ready().then(this.init); 
        
    },
    
    methods: {
        
        init() {           
            
            
            // 
            this.earth = new XE.Earth('cesiumContainer');
            this.viewer = this.earth.czm.viewer;
            window.viewer = this.viewer;
            MapViewInit(this.viewer);

            // 影像和高程
            MapAddDom_Tianditu(this.viewer);
            this.initGisDem();


            var that = this  

            MapFlyToDuration(this.viewer, 1, 119.15466077008783, 25.474044984387948, 30742.30795784303, 0, -90, 0);
        

            //实体左键点击事件
            var that = this;
            var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function (movement) {
                var pick = viewer.scene.pick(movement.position);
                MapDebugCamera(that.viewer, movement);                // 摄像机信息

                // if (Cesium.defined(pick) && (pick.id.id === 'id')) {
                if (Cesium.defined(pick) ) {             
                    that.$parent.selectCxxmid(pick.id.id);

                    // 选中放大
                    that.SelectPin(pick.id.id);

                }

            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

                
			this.mapCxxm.clear();
            for (var cxflid=1; cxflid<=14; cxflid++) {
                getCxxm(cxflid).then(res=>{
                    if (res.data.length > 0) {
                        
					    that.mapCxxm.set(res.data[0].cxflid, res.data);

				        // console.log(that.mapCxxm.get(res.data[0].cxflid));	

                        if (res.data[0].cxflid == 1) {
                            that.cxflid = res.data[0].cxflid;
                            that.selectCxflid(that.cxflid);
                        }
                    }
                })
            
            }


        },

        initGisDem() {
            
			// Gis配置（16）
			getCode("gis_set", "16").then(res=>{
				this.gisDemSetting = res.data[0];					
                
                console.log(this.gisDemSetting.value1);
                MapAddDem(this.viewer, this.gisDemSetting.value1);
			})

        },

		selectCxfl(cxfl) {

            // console.log("selectCxfl: " + cxfl);

            if (this.mapCxxm.has(cxfl.id)) {
                this.cxfl = cxfl;
                this.cxflid = cxfl.id;
                
                
                console.log("camera: " + cxfl.camera);
                // 摄像头参数转数组            
                var strarrcamera = this.cxfl.camera.split(",");
                var arrcamera = strarrcamera.map((value)=>{
                    return parseFloat(value);
                })
                if (arrcamera.length == 6) {
                    MapFlyToDuration(this.viewer, 1, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);                
                }
                else {
                    MapFlyToDuration(this.viewer, 1, 119.15466077008783, 25.474044984387948, 30742.30795784303, 0, -90, 0);
                }

                this.showPOI();
            }
            
		},

		selectCxflid(cxflid) {

            // console.log("selectCxflid: " + cxflid);

            if (this.mapCxxm.has(cxflid)) {
                this.cxflid = cxflid;
                
                this.showPOI();


                // // 摄像头参数转数组            
                // var strarrcamera = this.yqData[cameraindex].camera.split(",");
                // var arrcamera = strarrcamera.map((value)=>{
                //     return parseFloat(value);
                // })
                // if (arrcamera.length == 6) {
                //     MapFlyToDuration(this.viewer, 1, arrcamera[0], arrcamera[1], arrcamera[2], arrcamera[3], arrcamera[4], arrcamera[5]);                
                // }
                // else {
                //     MapFlyToDuration(this.viewer, 1, 119.15466077008783, 25.474044984387948, 30742.30795784303, 0, -90, 0);
                // }

            }
            
		},

        // 显示兴趣点
        showPOI() {
            MapViewerRemoveAll(this.viewer);

            var arrCxxm = this.mapCxxm.get(this.cxflid);                
            for (var i=0; i<arrCxxm.length; i++) {
                this.AddPin(this.cxflid, arrCxxm[i].id, arrCxxm[i].simplename, arrCxxm[i].longitude, arrCxxm[i].latitude);
            }

            // 默认选中第一个
            this.currentid = 0;
            if (arrCxxm.length > 0) {
				this.$parent.selectCxxmid(arrCxxm[0].id);
                this.SelectPin(arrCxxm[0].id);
            }

        },
        
        // 添加兴趣点
        AddPin(cxflid, id, name, lat, lng){                
            var littleimg = "./images/kjcx/l"+ cxflid +".png";

            // var arr1 = bd09togcj02(lat, lng)
            // var arr2 = gcj02towgs84(arr1[0], arr1[1])

            this.viewer.entities.add({
                id: id,
                name: name,
                cxflid: cxflid,
                position: Cesium.Cartesian3.fromDegrees(lat, lng),
                // position: Cesium.Cartesian3.fromDegrees(arr2[0], arr2[1]),
                label: {
                    text: name,
                    scale: 0.6,
                    fillColor: Cesium.Color.WHITE,
                    pixelOffset: new Cesium.Cartesian2(0, -this.little_height-20), //偏移量
                },
                billboard: {                    
                    image: littleimg,
                    width: this.little_width,
                    height: this.little_height,
                    
                    verticalOrigin : Cesium.VerticalOrigin.BOTTOM,//垂直位置
                    horizontalOrigin :Cesium.HorizontalOrigin.CENTER,//水平位置
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                }
            });
        },

        // 选择兴趣点
        SelectPin(id) {
        
            // 当前如果有选中，就还原
            if (this.currentid > 0) {
                var oldentity = this.viewer.entities.getById(this.currentid);
                                
                var littleimg = "./images/kjcx/l"+ oldentity.cxflid +".png";

                oldentity.billboard.image = littleimg;
                oldentity.billboard.width = this.little_width;
                oldentity.billboard.height = this.little_height;
                
                oldentity.label.scale = 0.6;
                oldentity.label.fillColor = Cesium.Color.WHITE;
                oldentity.label.pixelOffset = new Cesium.Cartesian2(0, -this.little_height-20);
                oldentity.label.showBackground = false;

                this.currentid = 0;
            }

            // 新选中的放大
            var newentity = this.viewer.entities.getById(id);

            var bigimg = "./images/kjcx/b"+ newentity.cxflid +".png";

            newentity.billboard.image = bigimg;
            newentity.billboard.width = this.big_width;
            newentity.billboard.height = this.big_height;
            
            
            newentity.label.scale = 0.8;
            newentity.label.fillColor = Cesium.Color.BLACK;
            newentity.label.pixelOffset = new Cesium.Cartesian2(0, -this.big_height-20);
            newentity.label.showBackground = true;
            newentity.label.backgroundColor = new Cesium.Color(0.5, 0.6, 1, 0.8),

            this.currentid = id;

        }




    }               

}



</script>


<style scoped>


</style>