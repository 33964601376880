<template>
  <h1></h1>

	<div class="panCxpt">		
    	<CXPT />
	</div>



	<div class="panmap">
		<div class="mapleft"></div>
		<div class="maptop"></div>
		
		<div class="map">
    		<MapKJCX ref="child_map" />
		</div>

		<div class="mapright"></div>
		<div class="mapbottom"></div>
	</div>

	<div>		
    	<DIALOG_TC ref="child_dialog_tc" />
    	<DIALOG_Video ref="child_dialog_video" />
    	<DIALOG_VRImg ref="child_dialog_vrimg" />
    	<DIALOG_VRVideo ref="child_dialog_vrvideo" />
	</div>
  
	<div class="panPtsm">		
    	<PTSM ref="child_ptsm" />
	</div>

	<div class="panFlsm">		
    	<FLSM ref="child_flsm" />
	</div>

	<div class="panCxsm">		
    	<CXSM ref="child_cxsm" />
	</div>

</template>

<script>
import MapKJCX from '@/components/map/MapKjcx.vue'

import CXPT from '@/components/kjcx/cxpt.vue'
import PTSM from '@/components/kjcx/ptsm.vue'
import FLSM from '@/components/kjcx/flsm.vue'
import CXSM from '@/components/kjcx/cxsm.vue'


import DIALOG_TC from '@/components/dialog/dialog_image.vue'
import DIALOG_Video from '@/components/dialog/dialog_video.vue'
import DIALOG_VRImg from '@/components/dialog/dialog_vrimg.vue'
import DIALOG_VRVideo from '@/components/dialog/dialog_vrvideo.vue'

import { getCxfl, getCxxm } from '../network/kjcx';


export default {

    data() 
	{ 
		return { 
			mapCxfl: new Map(),
		} 	
	}, 

	components: {
		MapKJCX,
		CXPT,
		PTSM,
		FLSM,
		CXSM,

		DIALOG_TC,
		DIALOG_Video,
		DIALOG_VRImg,
		DIALOG_VRVideo,
	
	},

	mounted() {
		this.init();
	},

	methods: {

		init() {

			getCxfl().then(res=>{
				this.mapCxfl.clear();

				for (var i=0; i<res.data.length; i++) {
					this.mapCxfl.set(res.data[i].id, res.data[i]);
				}

				// console.log(this.mapCxfl.size);
				// console.log(this.mapCxfl.get("2"));	

				this.selectCxfl(this.mapCxfl.get("1").id);
			})

		},

		// 选择创新项目
		selectCxfl(cxflid) {
			// console.log("创新分类" + cxflid);
			
			var cxfl = this.mapCxfl.get(cxflid);
			console.log(cxfl);	

			this.$refs.child_ptsm.selectCxfl(cxfl);
			this.$refs.child_flsm.selectCxfl(cxfl);
			
			this.$refs.child_map.selectCxfl(cxfl);
			// this.$refs.child_map.selectCxflid(cxfl.id);
		
		},
		selectAlltype() {
			// this.$refs.child_map.showAllPOI();			
		},

		// 选择配套工程
		selectCxxmid(cxxmid) {
			// console.log("创新项目id：" + cxxmid);
			this.$refs.child_cxsm.selectCxxm(cxxmid);
						
		},

		// 打开弹出框
		openTC(imagebox) {
			this.$refs.child_dialog_tc.open(imagebox);
		},
		openVideo(vadioURL) {
			this.$refs.child_dialog_video.open(vadioURL);
		},
		openVRImg(vrURL) {
			this.$refs.child_dialog_vrimg.open(vrURL);
		},
		openVRVideo(vrvadioURL, autoplay) {
			this.$refs.child_dialog_vrvideo.open(vrvadioURL, autoplay);
		},

	}

}
</script>

<style scoped>

.panmap {		
	top: 0px;
	left: 690px;
	width: 2460px;
	height: 945px;
	text-align: center;
  	position: relative;
  	overflow: hidden;
}


.panCxpt {	
	top: 79px;
	left: 30px;
	width: 650px;
	height: 837px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner1.png") no-repeat right bottom; 
}


.panPtsm {	
	top: 79px;
	left: 3160px;
	width: 650px;
	height: 199px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}


.panFlsm {	
	top: 292px;
	left: 3160px;
	width: 650px;
	height: 199px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}

.panCxsm {	
	top: 504px;
	left: 3160px;
	width: 650px;
	height: 411px;
	text-align: center;
	position: absolute;		
  	/* position:relative; */
	
	background: url("~@/assets/images/main/corner2.png") no-repeat left bottom; 
}



</style>