// 创新平台
<template>

	<div class="module">
		
		<div class="caption">
			<p>自主创新平台</p>
		</div>
		<div class="cxpt pan_class1">
			<ul>
				<li><p index=1 class="currentcxpt">院士工作站</p></li>
				<li><p index=2>国家级企业技术中心</p></li>
				<li><p index=3>省级企业技术中心</p></li>
				<li><p index=4>省级企业工程技术研究中心</p></li>
				<li><p index=5>省级重点实验室</p></li>
			</ul>
		</div>


		<div class="caption pos2">
			<p>公共服务平台</p>
		</div>		
		<div class="cxpt pan_class2">
			<ul>
				<li><p index=6>中科院STS网络科技服务中心</p></li>
				<li><p index=7>装备制造检测中心</p></li>
				<li><p index=8>中关村天和科技成果转化中心</p></li>
				<li><p index=9>海峡股权服务中心</p></li>
			</ul>
		</div>


		<div class="caption pos3">
			<p>创新创业孵化平台</p>
		</div>				
		<div class="cxpt pan_class3">
			<ul>
				<li><p index=10>省级众创空间</p></li>
			</ul>
		</div>


		<div class="caption pos4">
			<p>创新载体</p>
		</div>
		<div class="cxpt pan_class4">
			<ul>
				<li><p index=11>莆田学院</p></li>
				<li><p index=12>湄洲湾职业技术学院</p></li>
				<li><p index=13>东南新工科产业学院</p></li>
				<li><p index=14>北理工东南信息研究院</p></li>
			</ul>
		</div>

	</div>
  
</template>



<script>

export default {

	mounted (){		
		this.init();

	},

	methods: {
		
		init() {			
			var that = this;
							
			var labeltabs = document.querySelectorAll(".cxpt ul li p")
			for (var i = 0; i<labeltabs.length; i++) {
				labeltabs[i].addEventListener('click', function() {
					
					for (var j = 0; j < labeltabs.length; j++) {
						labeltabs[j].className = '';
					}
					this.className = 'currentcxpt';

					var index = this.getAttribute('index');    
					that.$parent.selectCxfl(index);
					                   
				})
			}
 
			// 默认选择第一个
			// that.$parent.selectCxfl(1);

		},

	}

}

</script>



<style scoped>

.module .pos2 {	
	top: 260px;
}

.module .pos3 {	
	top: 435px;
}

.module .pos4 {	
	top: 540px;
}


.pan_class1 {	
	top: 50px;
	left: 15px;
	width: 620px;
	height: 620px;
	position: absolute;		
}

.pan_class2 {	
	top: 330px;
	left: 15px;
	width: 620px;
	height: 620px;
	position: absolute;		
}

.pan_class3 {	
	top: 540px;
	left: 15px;
	width: 620px;
	height: 620px;
	position: absolute;		
}

.pan_class4 {	
	top: 680px;
	left: 15px;
	width: 620px;
	height: 620px;
	position: absolute;		
}

li {    
	margin: 10px 7px;
	float: left;
    width: 295px;
	height: 50px;
	cursor: pointer;
}

li p{    
	
  	font-size: 20px;
  	line-height: 50px;
	text-align: center;    
    border-radius: 25px;	
    border: 1px solid #9BF5ED;
	color: rgb(255, 255, 255, 0.8);	

    background-color: rgb(255, 255, 255, 0.025);	

}

li .currentcxpt{    
	color: rgb(0, 0, 0, 1);	
    background-color: rgba(155, 245, 237, 1);	
}



</style>