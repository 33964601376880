<template>

	<el-dialog title="" v-model="dialogVisible" top="23vh" width="0%">
		<div class="dialog">       
            <p class="caption">图册</p>
            <p class="close" @click="close"></p>

            <div class="diaimg">
                <el-carousel :interval="5000" arrow="always" height="803px" weight="2300px">
                    <el-carousel-item v-for="item in imagebox" :key="item.id">
                        <img class="imgshow" :src="item.idView" alt="">
                    </el-carousel-item>
                </el-carousel>
            </div>

		</div>
	</el-dialog>

</template>


<script>

export default {

    data() { 
		return { 
			dialogVisible: false,
            imagebox:[],
		} 	
	}, 
    
	methods: {
		open(imagebox) {
			this.imagebox = imagebox;
			this.dialogVisible = true;
		},

        close() {
			this.dialogVisible = false;
        }
    }

}
</script>

<style>

.el-dialog {
    position: absolute;
    width: 1000px;
    height: 1000px;
 
    top: 0px;
    left: 0px; 	

	/* background-color: cornflowerblue; */
}

.dialog {
    position: fixed;
	top: 0px;
	left: 670px;
	width: 2500px;
	height: 900px;
	background: url("~@/assets/images/main/dialogback.png") no-repeat; 
}

.dialog .caption {
    position: absolute;

	top: 20px;
	left: 55px;
	font-size: 16px;
	color: rgb(255, 255, 255, 0.8);	
}
.dialog .close {
    position: absolute;

	top: 15px;
	right: 15px;
	width: 32px;
	height: 32px;

	background: url("~@/assets/images/main/dialogclose.png") no-repeat; 
}

.diaimg {
    position: absolute;
    
	top: 50px;
	left: 50px;
	width: 2400px;
	height: 803px;

}

.el-carousel__item {
    background-color:black;
}

.imgshow {
	top: 100px;
	left: 100px;
	width: 2400px;
	height: 803px;
    object-fit: contain;
}

</style>