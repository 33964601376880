<template>
  
	<el-dialog title="" v-model="dialogVisible" top="23vh" width="0%" @close='closeDialog'>
		<div class="dialog">       
            <p class="caption">宣传视频</p>
            <p class="close" @click="close"></p>

            <div class="diaimg">
				<video-player ref="videoPlayer" :playsinline="true" :options="playerOptions">
        		</video-player>
            </div>
		</div>
	</el-dialog>

</template>


<script>

export default {

    data() { 
		return { 
			dialogVisible: false,

			playerOptions: {                    
				// playbackRates: [0.5, 1.0, 1.5, 2.0], 		//播放速度                
				autoplay: true,     //如果true,浏览器准备好时开始回放。                    
				muted: false, 		// 默认情况下将会消除任何音频。                    
				loop: true, 	// 导致视频一结束就重新开始。                    
				preload: 'auto', 	// 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',                     
				aspectRatio: '2400:803',	// 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）                     
				fluid: true,	// 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [{                        
					type: "video/mp4",	//类型                        
					src: 'http://127.0.0.1:1234/test.mp4' 	//url地址
				}],                    
				poster: '', 	//你的封面地址                     
				notSupportedMessage: '此视频暂无法播放，请稍后再试',	//允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: true,
					durationDisplay: true,
					remainingTimeDisplay: false,                        
					fullscreenToggle: true  	//全屏按钮
				}
			}

		} 	
	}, 

	computed: {
		player() {
			return this.$refs.videoPlayer.player;
		}
	},


	methods: {

		// 
		open(vadioURL) {			
			this.playerOptions.sources[0].src = vadioURL;

			this.dialogVisible = true;					
  			this.player.play();//播放						
		},

        close() {
			this.dialogVisible = false;
			this.player.pause();//暂停
        },

		closeDialog(){
			this.player.pause();//暂停
		},
    }

}
</script>

<style>

.el-dialog{
    width: 1px;
    height: 1px; 
    top: -300px;
    left: 0px; 
    position: absolute;
}

.dialog {
	top: 110px;
	left: 670px;
	width: 2500px;
	height: 900px;
    position: absolute;
	background: url("~@/assets/images/main/dialogback.png") no-repeat; 
}

.dialog .caption {
	top: 20px;
	left: 55px;
	font-size: 16px;
    position: absolute;
	color: rgb(255, 255, 255, 0.8);	
}

.dialog .close {
	top: 15px;
	right: 15px;
	width: 32px;
	height: 32px;    
    position: absolute;
	background: url("~@/assets/images/main/dialogclose.png") no-repeat; 
}

.diaimg {    
	top: 50px;
	left: 50px;
	width: 2400px;
	height: 803px;
    position: absolute;
}


</style>