// 创新说明
<template>

	<div class="module">
		<div class="caption">
			<p>{{ cxxmname }}</p>
		</div>
		
		<div class="pan">	
			<!-- <img class="showimage" :src="require('../../assets/images/main/null.png')" alt=""> -->
			<img class="showimage" ref="showcover" src="/images/nopicture.gif" alt="">
			
			<p class="content">{{ introduction }}</p>
		</div>
		

		<ul>
			<li>			
				<img src="~@/assets/images/kjcx/btnXCSP.png" alt="" @click="opentc">
				<div ref="btn1" class="capt btn1" @click="opentc">
					<p>图册</p>
				</div>
			</li>
			<li>		
				<img src="~@/assets/images/kjcx/btnSJVR.png" alt="" @click="openvideo">
				<div ref="btn3" class="capt btn3" @click="openvideo">
					<p>视频</p>
				</div>
			</li>
			<li>		
				<img src="~@/assets/images/kjcx/btnVRSP.png" alt="" @click="openvrimg">
				<div ref="btn4" class="capt btn4" @click="openvrimg">
					<p>实景VR</p>
				</div>
			</li>
		</ul>

	</div>
  
</template>



<script>
import { getCxfl, getCxxm, getCxxmById } from '../../network/kjcx';
import { getMedia } from '../../network/media';

export default {

    data() 
	{ 
		return { 
			mediaSetting: {},
			
			cxxmid: 0,			
			cxxmData: [],

		} 	
	}, 

	computed: {
		
		cxxmname: function(){
			if (this.cxxmData.length > 0) {
				return this.cxxmData[0].cxxmname;
			}
			else {
				return "";
			}
		},

		introduction: function(){
			if (this.cxxmData.length > 0) {
				return this.cxxmData[0].introduction;
			}
			else {
				return "";
			}
		},

		showcover: function() {
			return 'images/main/nopicture.gif';
		}
	},

	mounted() {
		this.init();
	},

	methods: {
		
		init() {

			// （17）的多媒体资源设置
			getMedia("17").then(res=>{
				this.mediaSetting = res.data[0];	
				// console.log(this.mediaSetting.prefix);
				
			})

		},

		selectCxxm(cxxmid) {
			this.cxxmid = cxxmid;
			
			getCxxmById(this.cxxmid).then(res=>{
				this.cxxmData = res.data;	
				this.setState();
			})
			
		},


		// 设置状态
		setState() {

			// 设置状态
			if (this.cxxmData[0].image.length > 0) {
				this.$refs.btn1.style.opacity = 1;

				// 显示封面
				var arrtc = this.cxxmData[0].image.split(",");
				var imgpath = this.mediaSetting.prefix + this.cxxmname + "/" + this.mediaSetting.images + "/" + arrtc[0].trim();
				// console.log(imgpath);
				this.$refs.showcover.src = imgpath;


			}
			else {
				this.$refs.btn1.style.opacity = 0.5;
				this.$refs.showcover.src = "/images/nopicture.gif";
			}
			
			// if (this.cxxmData[0].image.length > 0) {
			// 	this.$refs.btn2.style.opacity = 1;
			// }
			// else {
			// 	this.$refs.btn2.style.opacity = 0.5;
			// }
			
			if (this.cxxmData[0].video.length > 0) {
				this.$refs.btn3.style.opacity = 1;
			}
			else {
				this.$refs.btn3.style.opacity = 0.5;
			}
			
			if (this.cxxmData[0].vrpano.length > 0) {
				this.$refs.btn4.style.opacity = 1;
			}
			else {
				this.$refs.btn4.style.opacity = 0.5;
			}		
		},

		// 打开对话框
		opentc() {

			if (this.cxxmData[0].image.length <= 0) {
				return;
			}

            var imagebox = [];
			var arrtc = this.cxxmData[0].image.split(",");
			for (var i=0; i<arrtc.length; i++)
			{
				var tcimage = {};
				tcimage.id = i;
				tcimage.idView = this.mediaSetting.prefix + this.cxxmname + "/" + this.mediaSetting.images + "/" + arrtc[i].trim();

				console.log(tcimage.idView);
				imagebox.push(tcimage);
			}

			this.$parent.openTC(imagebox);
		},
		openvideo() {
			if (this.cxxmData[0].video.length <= 0) {
				return;
			}
			
			var vadioURL= this.mediaSetting.prefix + this.cxxmname + "/" + this.mediaSetting.video + "/" + this.cxxmData[0].video
			// var vadioURL= this.mediaSetting.video + "/" + this.cxxmData[0].video
			
			console.log(vadioURL)

			this.$parent.openVideo(vadioURL);
		},
		openvrimg() {
			if (this.cxxmData[0].vrpano.length <= 0) {
				return;
			}

			// var vrURL= this.mediaSetting.prefix + this.cxxmname + "/" + this.mediaSetting.vrimg + "/" + this.cxxmData[0].vrpano
			
			// http://127.0.0.1:8015/smartgaoxin/data/专题/十个一/海西研究院/全景/index.html?go2=g20190315170939_s20190315172334
			var vrURL= this.mediaSetting.vrimg + "/" + this.cxxmData[0].vrpano

			console.log(vrURL)

			this.$parent.openVRImg(vrURL);
		},
		
	}


}

</script>



<style scoped>

.pan {	
	top: 15px;
	left: 10px;
	width: 630px;
	height: 275px;
	border-radius:10px;
  	position:relative;
	background-color: #152a3a;	
		
	
}

.showimage {

	top: 5px;
	left: 2px;
	width: 285px;
	height: 265px;
	position: absolute;	
	border-radius:10px;
	background-color: #253a4a;	
    object-fit: contain;
	
	/* background-color: green; */
}

.content {

	top: 5px;
	left: 295px;
	width: 335px;
	height: 265px;
	position: absolute;	

	overflow: auto;
	font-size: 14px;
	line-height: 18px;
	text-align: left;
	text-indent: 2em;
	color: rgb(255, 255, 255, 0.8);
	
	/* background-color: red; */
}



ul {
	left: 5px;
	top: 330px;
  	position:absolute;
}

li {
	margin: 5px;
	float: left;
	width: 203px;
	height: 103px;
	/* background-color: red; */
	position: relative;
	cursor: pointer;
}


.capt {	
	top: 18px;
	width: 203px;
	height: 34px;
	text-align: center;
	position: absolute;			
}

.capt p {	
	left: 32px;
	color: #F1F1F1;
  	font-size: 22px;
  	line-height: 34px;
  	text-align: left;
  	position:relative;	
	color: rgb(255, 255, 255, 1);
}

.btn1 {	
	/* left: 45px; */
	left: 60px;
	/* background: url("~@/assets/images/main/icoBF.png") no-repeat left;  */
	background: url("~@/assets/images/main/icoTC.png") no-repeat left; 
	opacity: 1;
}

.btn2 {	
	left: 45px;
	background: url("~@/assets/images/main/icoTC.png") no-repeat left; 
	opacity: 1;
}

.btn3 {	
	/* left: 45px; */
	left: 60px;
	/* background: url("~@/assets/images/main/icoVR.png") no-repeat left;  */
	background: url("~@/assets/images/main/icoBF.png") no-repeat left; 
	opacity: 1;
}

.btn4 {	
	left: 45px;
	/* background: url("~@/assets/images/main/icoSP.png") no-repeat left;  */
	background: url("~@/assets/images/main/icoVR.png") no-repeat left; 
	opacity: 1;
}

</style>