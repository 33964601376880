<template>
  
	<el-dialog title="" v-model="dialogVisible" top="23vh" width="0%" @close='closeDialog'>
		<div class="dialog">    

            <p class="caption">VR视频</p>
            <p class="close" @click="close"></p>

            <div class="diaimg">				
				<iframe ref="vrframe" style="border:none" width="100%" height="100%" v-bind:src="vrurl"></iframe>
            </div>

		</div>
	</el-dialog>

</template>

<script>

export default {

    data() { 
		return { 
			dialogVisible: false,
			
			// 播放器
			player: "tools/utovr/play_utovr.html",

			// inframe: "http://127.0.0.1:1234/play_utovr.html?video=http://127.0.0.1:1234/vrvideo.mp4",
			// inframe: "http://127.0.0.1:1234/vrvideo.mp4",
			inframe: "",
			autoplay: "true",
		} 	
	}, 

	computed: {
		vrurl() {
			console.log(this.player + "?video=" + this.inframe + "&auto=" + this.autoplay);
			return this.player + "?video=" + this.inframe + "&auto=" + this.autoplay;
		}
	},

	mounted() {
		this.init();
	},


	methods: {

		init() {

		},

		// 
		open(vrvadioURL, autoplay) {

			if (this.inframe.length > 0) {
				this.$refs.vrframe.contentWindow.location.reload();
				console.log("this.$refs.vrframe.contentWindow.location.reload();");
			}

			this.inframe = vrvadioURL;
			this.autoplay = autoplay;

			this.dialogVisible = true;						
			
		},

        close() {			
			if (this.dialogVisible == true) {
				// this.$refs.vrframe.style.display = "none";
				this.dialogVisible = false;
			}

        },

		closeDialog(){			

		},
    }

}
</script>

<style>

.el-dialog{
    position: absolute;
    width: 1px;
    height: 1px;
 
    top: -300px;
    left: 0px; 
}

.dialog {
    position: absolute;
	top: 110px;
	left: 670px;
	width: 2500px;
	height: 900px;
	background: url("~@/assets/images/main/dialogback.png") no-repeat; 
}

.dialog .caption {
    position: absolute;

	top: 20px;
	left: 55px;
	font-size: 16px;
	color: rgb(255, 255, 255, 0.8);	
}
.dialog .close {
    position: absolute;

	top: 15px;
	right: 15px;
	width: 32px;
	height: 32px;

    
	background: url("~@/assets/images/main/dialogclose.png") no-repeat; 
}


.diaimg {
    position: absolute;
    
	top: 50px;
	left: 50px;
	width: 2400px;
	height: 803px;
}


</style>