// 平台说明
<template>

	<div class="module">
		<div class="caption">
			<p>{{ cxfl.cxptname }}</p>
		</div>
		
		<div class="pan">	
			<p class="content">{{ cxfl.cxptintroduction }}</p>
		</div>
	</div>
  
</template>


<script>

export default {

    data() 
	{ 
		return { 
			cxfl: {},	// 创新分类（有创新平台数据）
		} 	
	}, 

	methods: {
		
		selectCxfl(cxfl) {
			this.cxfl = cxfl;						
		},
	}

}

</script>


<style scoped>


.pan {	
	top: 10px;
	left: 10px;
	width: 630px;
	height: 145px;
	border-radius:10px;
  	position:relative;
	background-color: #152a3a;	
}


.content {

	top: 10px;
	left: 10px;
	width: 610px;
	height: 125px;
	position: absolute;	

	overflow: auto;
	font-size: 14px;
	line-height: 18px;
	text-align: left;
	text-indent: 2em;
	color: rgb(255, 255, 255, 0.8);
	
	/* background-color: red; */
}

</style>